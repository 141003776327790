
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AcademyAD extends Vue {
  //links
  //fb: https://www.facebook.com/sharer.php?u=[post-url]
  //line: https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fline.me%2Fen
  writeText: any;
  viewWidth = 0; //null
  mounted(): void {
    this.appSource();
    const facebookBtn = document.querySelector(".facebook-button") as Element;
    const lineBtn = document.querySelector(".line-button") as Element;
    let postUrl = encodeURI(document.location.href);
    facebookBtn.setAttribute(
      "href",
      `https://www.facebook.com/sharer.php?u=${postUrl}`
    );
    lineBtn.setAttribute(
      "href",
      `https://social-plugins.line.me/lineit/share?url=${postUrl}`
    );
  }
  copyToClipBoard(): Promise<void> {
    let postUrl = encodeURI(document.location.href);
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard 向剪贴板写文本
      alert("已複製連結");
      return navigator.clipboard.writeText(postUrl);
    } else {
      // 创建text area
      let textArea = document.createElement("textarea");
      textArea.value = postUrl;
      // 使text area不在viewport，同时设置不可见
      textArea.style.position = "absolute";
      textArea.style.opacity = "0";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
        alert("已複製連結");
      });
    }
  }
  //識別裝置是apple/android
  appSource(): void {
    this.viewWidth = window.innerWidth;
    window.onresize = () => {
      this.viewWidth = window.innerWidth;
    };
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS && this.viewWidth < 576) {
      let iosBtnBar = document.querySelector(".icons") as HTMLElement;
      iosBtnBar.style.paddingBottom = "10px";
    } else {
      return;
    }
  }
}
